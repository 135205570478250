'use strict';
var localizationObject = {
    ru: {
        'resources.1': 'Товары',
        'resources.2': 'AИ-80',
        'resources.3': 'АИ-92',
        'resources.4': 'АИ-95',
        'resources.5': 'АИ-98',
        'resources.6': 'ДТ',
        'resources.7': 'Масло',
        'resources.8': 'Газ',
        'resources.9': 'Услуги',

        'azs_state_0': 'В работе',
        'azs_state_1': 'Блокирована',

        notData: 'Нет данных',

        units_pieces: 'шт.',
        units_kg:     'кг',
        units_rub:    'руб.',
        units_liter:  'л',

        balancesTanks_title:            'Остатки по резервуарам',
        balancesTanks_reservoirs_title: 'Резервуары',

        balancesTanks_total:   'Свободно:',
        balancesTanks_balance: 'Доступно:',
        balancesTanks_density: 'Плотность:',
        balancesTanks_shift:   'Смена №',

        balancesTanks_density_short: 'кг/л',
        balancesTanks_mass:          'кг',
        balancesTanks_volume:        'л',

        balancesTanks_table_heading:         'Резервуар',
        balancesTanks_table_start_val:       'Начало смены',
        balancesTanks_table_delta:           'Отпущено',
        balancesTanks_table_consignment_val: 'Принято',
        balancesTanks_table_available:       'Свободно',
        balancesTanks_table_end_val:         'Конец смены',

        balancesVarieties_title: 'Остатки по сортам',

        balancesVarieties_table_heading:        'Топливо:',
        balancesVarieties_resource:             'Тип ресурса:',
        balancesVarieties_real_val:             'объем фактический (л)',
        balancesVarieties_total_volume:         'отпущено (л)',
        balancesVarieties_real_mass:            'масса фактическая (кг)',
        balancesVarieties_total_mass:           'отпущено (кг)',
        balancesVarieties_book_val:             'объем книжный (л)',
        balancesVarieties_total_sum:            'отпущено (руб.)',
        balancesVarieties_book_mass:            'масса книжная (кг)',
        balancesVarieties_consignment_val:      'принято(л)',
        balancesVarieties_delta:                'баланс (факт-книжн), л*',
        balancesVarieties_consignment_mass_val: 'принято(кг)',
        balancesVarieties_delta_mass:           'баланс (факт-книжн), кг*',
        balancesVarieties_density:              'плотность отпуска',

        oilTurnover_title: 'Оборот ТНП',
        oilTurnover_total: 'Всего:',

        realizationOilProduct_title:    'Реализация НП',
        realizationOilProduct_total:    'Всего:',
        realizationOilProduct_in_total: 'Итого по:',
        realizationOilProduct_discount: 'в т.ч. по ДК',

        dynamics_realization_sum_volume: 'Суммарный объем',
        dynamics_realization_sum_cost:   'Суммарная стоимость',
        dynamics_realization_volume:     'Объем',
        dynamics_realization_cost:       'Стоимость',

        fuelDispensersCounters_title:    'Расход НП по ТРК',
        periodsRealization_title:        'Реализация НП',

        periodsRealization_total_goods:  'Итого по',
        periodsRealization_total_azs:    'Итого по АЗС',
        periodsRealization_total:        'Итого по всем АЗС',
        periodsRealization_azs:          'АЗС',
        periodsRealization_shifts:       'смены',
        periodsRealization_shift_start:  'с',
        periodsRealization_shift_end:    'по',

        operation_failed: 'Не удалось выполнить операцию.',

        d3Localization: {
            'decimal'    : ',',
            'thousands'  : '.',
            'grouping'   : [3],
            'currency'   : ['₽', ''],
            'dateTime'   : '%a %b %e % %Y',
            'date'       : '%d.%m.%Y',
            'time'       : '%H:%M:%S',
            'periods'    : ['AM', 'PM'],
            'days'       : ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
            'shortDays'  : ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            'months'     : ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
            'shortMonths': ['Янв', 'Февр', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт', 'Нояб', 'Дек']
        },

        groupGoodsAdd: 'Добавить группу товаров',
        groupGoods: 'Группа товаров',
        countGoods: 'Количество товаров',
        groupGoodsEdit: 'Изменить группу товаров',
        goodsAdd: 'Добавить товар',
        goodsEdit: 'Изменить товар',
        goods: 'Товар',
    }
};